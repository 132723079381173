/* You can add global styles to this file, and also import other style files */
.ng-select-sm.ng-select.ng-select-single .ng-select-container {
    height: calc(1.5em + .5rem + 2px) !important;
}

.ng-select-sm.ng-select .ng-select-container {
    min-height: calc(1.5em + .5rem + 2px) !important;
}

.custom-group-height {
    height: 60px;
}

.custom-group-height small {
    margin-top: 0px;
}

/* PAGINATIONS STYLES */

@media screen and (max-width: 991px) {
    .paginator-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 2px;
    }
}

@media screen and (min-width: 992px) {
    .paginator-container {
        display: flex;
        justify-content: space-between;
        margin-top: 2px;
    }
}

.paginator-select {
    display: flex;
    justify-content: center;
}

.horizontal-scroll-wrapper-container {
    width: 82vw;
    white-space: nowrap;
    height: calc(100vh - 155px);
    overflow-x: auto;
    overflow-y: hidden;
    /*background-color: pink;*/
}

.horizontal-scroll-wrapper-element {
    width: 12rem;
    /*margin: 0 10px 0 10px;*/
    height: auto;
    display: inline-block;
    /*background-color: black;*/
}

.vertical-scroll-wrapper-container {
    width: 25rem;
    height: calc(100vh - 220px);
    overflow-y: auto;
    overflow-x: hidden;
}

.vertical-scroll-wrapper-element {
    width: 25rem;
    height: auto;
}

.text-overflow-100 {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100px;
    white-space: nowrap;
}

.text-overflow-120 {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 120px;
    white-space: nowrap;
}

.text-overflow-140 {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 140px;
    white-space: nowrap;
}

/*  ESTILOS PERSONALISADOS PARA TABLA */

table thead {
    color: #fff;
    background-color: #B91646;
    /*background-color: #C2185B;*/
}

.color-tag {
    color: #B91646;
}

/* titles */
h2, h4, h5 {
    color: #B91646;
}

/* sub-titles */
h6 {
    color: #105652;
}

/* background cads*/
.card-color .card {
    background-color: #FBF3E4;
}

.custom-pagination ::ng-deep .ngx-pagination .current {
    background: #612651;
}

.text-overflow-180 {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 180px;
    white-space: nowrap;
}

.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #102235;
    border-color: #102235;
}

.pondVero {
    display: flex;
    justify-content: center;
    align-items: center;
}

.input-search {
    flex-grow: 1;
}

.btn-group-search {
    flex-grow: 0;
    margin-left: 4px;
}

/* modal view image*/
.img-viewer-h > .img-container {
    height: 95vh !important;
}

.img-viewer-h-tab > .img-container {
    height: calc(100vh - 320px) !important;
}
