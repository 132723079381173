.wrapper-icon{
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
}

@keyframes shimmer {
    from {
        left: -30px
    }

    to {
        left: 50px
    }
}

#initial_startup {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 136px;
    height: 136px;
    border-radius: 50%;
    user-select: none;
    background-color: #f0f0f0;
    top: 0;
    left: 0;
    margin-left: calc(100%/2 - 136px/2);
    margin-top: 10px;
}

#initial_startup .graphic {
    color: #bfbfbf
}

#initial_startup .graphic::after {
    content: '';
    position: relative;
    top: -100%;
    left: 0px;
    display: block;
    width: 50px;
    height: 100%;
    background: linear-gradient(to right, rgba(240, 240, 240, 0) 0, rgba(240, 240, 240, 0) 33.33%, rgba(240, 240, 240, .5) 44.1%, rgb(240, 240, 240) 55.8%, rgba(240, 240, 240, 0) 66.66%, rgba(240, 240, 240, 0) 100%);
    opacity: 1;
    animation: shimmer 1.5s linear .6s infinite
}

#initial_startup .graphic .wrapper-svg{
    width: 80px;
    height: 80px;
}

#initial_startup .graphic .wrapper-svg {
    /* filter: grayscale(100%); */
}
